import * as React from "react"
import Seo from "../components/seo"
import Hero from "../components/sections/publishing/hero/Hero"
import Packages from "../components/sections/publishing/packages/Packages";
import { Helmet } from "react-helmet"

const PrivacyPolicyPage = () => (
  <>
    <Seo title="Publishing Services" />
    <Helmet bodyAttributes={{ class: "header-class" }} />
    <Hero/>
    <Packages/>
  </>
)

export default PrivacyPolicyPage
